
/*Sign In*/
.orderSummaryPage .register-logo-wrap{background:#fff;padding:10px 15px;}
.orderSummaryPage .register-logo-wrap a{display:inline-block;max-width:250px;}
.orderSummaryPage .register-logo-wrap a img{max-width:100%;}
.orderSummaryPage .register-wrap{min-height:calc(100vh - 87px) !important;background:#fff;padding-top:30px;padding-bottom:100px;-webkit-align-items:center;align-items:center;-webkit-flex-direction:column;flex-direction:column;display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;}
.orderSummaryPage .register-wrap a{-webkit-transition:all 0.35s ease 0s;transition:all 0.35s ease 0s;}
.orderSummaryPage .register-box{padding:55px 0;display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;width:1000px;}
.orderSummaryPage .register-head{text-align:center;font-size:20px;font-weight:500;}
.orderSummaryPage .register-head h2{font-weight:bold;font-size:3.7rem;line-height:1.3;color:#3d5170;margin:0 0 25px;}
.orderSummaryPage .register-head p:last-child{margin-bottom:0;}
.orderSummaryPage .register-box .register-form-wrap{width:100%;padding:20px 70px 20px 0;}
.orderSummaryPage .register-form-wrap .form-group{position:relative;margin-bottom:30px;}
.orderSummaryPage .register-form-wrap .form-group input{-webkit-border-radius:0;border-radius:0;;font-size:18px;line-height:22px;font-weight:normal;border:none;border-bottom:1px solid #DADADA;padding:15px 17px 15px;-webkit-box-shadow:none;box-shadow:none;}
.orderSummaryPage .register-form-wrap .form-group select{-webkit-border-radius:0;border-radius:0;;font-size:18px;line-height:22px;font-weight:normal;border:none;border-bottom:1px solid #DADADA;-webkit-box-shadow:none;box-shadow:none;}
.orderSummaryPage .register-form-wrap .form-group label{margin:0;pointer-events:none;font-weight:500;font-size:18px;line-height:20px;color:#3d5170;position:absolute;top:14px;left:17px;-webkit-transition:all 0.35s ease 0s;transition:all 0.35s ease 0s;}
.orderSummaryPage .register-form-wrap .form-group input:focus ~ label,.register-form-wrap .form-group.active input ~ label{top:-15px;font-size:16px;}
.orderSummaryPage .register-form-wrap .form-group select:focus ~ label,.register-form-wrap .form-group.active select ~ label{top:-15px;font-size:16px;}
.orderSummaryPage .register-form-wrap .box-extra-info{font-size:16px;font-weight:500;white-space:nowrap;}
.orderSummaryPage .register-form-wrap .box-extra-info a{text-decoration:none;}
.orderSummaryPage .reg-frm-action  .btn{-webkit-border-radius:100px;border-radius:100px;font-size:20px;line-height:22px;font-weight:500;padding:13px 30px 15px;min-width:170px;text-align:center;border:2px solid #007bff;background:transparent;color:#007bff;outline:none;}
.orderSummaryPage .reg-frm-action{margin-top:40px;}
.orderSummaryPage .reg-frm-action .btn:hover{color:#fff;background:#007bff;}
.orderSummaryPage .social-icon-btn-wrap a{text-decoration:none;margin:0 0 30px;font-size:17px;line-height:22px;padding:15px 20px 15px 70px;position:relative;text-align:center;display:inline-block;width:300px;max-width:100%;border:1px solid;height:56px;outline:none;}
.orderSummaryPage .social-icon-btn-wrap a:last-child{margin-bottom:0;}
.orderSummaryPage .social-icon-btn-wrap .facebook-btn{background:#3d5b96;color:#fff;border-color:#3d5b96;}
.orderSummaryPage .social-icon-btn-wrap .google-btn{background:#4688f1;color:#fff;border-color:#4688f1;}
.orderSummaryPage .social-icon-btn-wrap .apple-btn{color:#000000;}
.orderSummaryPage .social-icon-btn-wrap a i{font-size:26px;line-height:54px;position:absolute;width:54px;height:54px;border-right:1px solid;top:0;bottom:0;left:0;background:#fff;}
.orderSummaryPage .social-icon-btn-wrap .google-btn i{color:#4688f1;}
.orderSummaryPage .social-icon-btn-wrap .facebook-btn i{color:#3d5b96;}
.orderSummaryPage .reg-box-note{text-align:center;font-size:16px;line-height:28px;}
.orderSummaryPage .reg-box-note p:last-child{margin-bottom:0;}
.orderSummaryPage .reg-box-note a{text-decoration:none;}



/*Responsive*/
@media only screen and (max-width:1024px){
.orderSummaryPage .register-wrap{padding-top:0;padding-bottom:40px;}
.orderSummaryPage .register-box{padding:30px 0;width:900px;}
.orderSummaryPage .register-head h2{font-size:3.5rem;margin:0 0 20px;}
.orderSummaryPage .register-box .register-form-wrap{padding-right:50px;}
.orderSummaryPage .register-box .register-btn-wrap{padding-left:50px;}
}

@media only screen and (max-width:992px){
.orderSummaryPage .register-box{width:550px;-webkit-flex-wrap:wrap;flex-wrap:wrap}
.orderSummaryPage .register-box .register-form-wrap{width:100%;padding:0 0 40px;border-right:none;border-bottom:2px solid #9a9a9a;}
.orderSummaryPage .register-box .register-btn-wrap {width:100%;padding-left:0;padding-top:40px;text-align:center;}
.orderSummaryPage .register-btn-wrap .social-icon-btn-wrap {max-width:400px;margin:auto;}
}

@media only screen and (max-width:767px){
.orderSummaryPage .register-box{width:500px}
.orderSummaryPage .register-head{font-size:18px;}
.orderSummaryPage .register-head h2{font-size:3rem}
.orderSummaryPage .reg-frm-action .btn{padding: 11px 30px 13px;}
.orderSummaryPage .register-form-wrap .form-group input:focus ~ label, .register-form-wrap .form-group.active input ~ label{top:-10px}
}

@media only screen and (max-width:568px) and (orientation:landscape){
.orderSummaryPage .register-box{width:100%}
}

@media only screen and (max-width:480px){
.orderSummaryPage .register-wrap{padding-top:10px;padding-bottom:30px;}
.orderSummaryPage .register-box{width:100%}
.orderSummaryPage .social-icon-btn-wrap a{width:284px}
.orderSummaryPage .register-form-wrap .box-extra-info{font-size:15px;}
.orderSummaryPage .reg-box-note br{display:none;}
.orderSummaryPage .register-logo-wrap{text-align:center}
.orderSummaryPage .register-logo-wrap a{max-width:230px}
}

@media only screen and (max-width:360px){
.orderSummaryPage .register-form-wrap .box-extra-info{font-size: 14px;}
}

@media only screen and (max-width:359px){
.orderSummaryPage .register-form-wrap .box-extra-info .col.text-right{text-align:left !important;margin-top:15px}
}

