.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}
.main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}
